<template>
  <div class="flex flex-col justify-center mt-40">
    <div class="text-center">
      <img
        src="~/assets/img/system_error_img.png"
        alt="404"
      >
    </div>

    <div class="text-center mt-4">
      <el-button
        class="gs_primary_btn"
        type="primary"
        @click="handleError"
      >
        返回首页
      </el-button>
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  error: Object
})
console.log('error', props.error)
const handleError = () => clearError({ redirect: '/' })
</script>
